var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogFormRef",
    attrs: {
      readonly: !!_vm.readonly,
      title: _vm.title,
      form: _vm.form,
      action: _vm.action,
      width: "100%",
      "item-width": "45%",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      done: v => _vm.$emit("done", v)
    },
    scopedSlots: _vm._u([{
      key: "productSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("TableForm", {
          ref: "tableFormRef",
          attrs: {
            model: model,
            prop: "product",
            columns: _vm.tableFormColumns,
            readonly: !!_vm.readonly
          },
          on: {
            addItem: _vm.makeAmount,
            removeItem: _vm.makeAmount
          }
        })];
      }
    }, !_vm.row || !_vm.row.id ? {
      key: "footer",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            loading: _vm.loading,
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.temporarily_store();
            }
          }
        }, [_vm._v("暂存")]), !_vm.readonly ? _c("el-button", {
          attrs: {
            loading: _vm.loading,
            size: _vm.size,
            type: "primary"
          },
          on: {
            click: _vm.onSubmit
          }
        }, [_vm._v(" 提交 ")]) : _vm._e(), _c("el-button", {
          attrs: {
            size: _vm.size
          },
          on: {
            click: _vm.close
          }
        }, [_vm._v(" 取消 ")])];
      },
      proxy: true
    } : null], null, true)
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };